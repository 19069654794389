import React, { useEffect, useState } from 'react';
import ViewOrderDetails from './orderDetails';
import CustomerDetails from './customerDetails';
import ProductQuestions from './productQuestions';
import { useSelector } from 'react-redux';
import api from '../../../api/api';
import { useDispatch } from 'react-redux';
import order, {
  emptyOrderData,
  getOrderById,
} from '../../../store/reducers/order';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toastSuccess, toastError} from '../../../common/UI/Toasts';
import {
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
  validateFileExtensions,
  getFormDataFromObject,
} from '../../../utils/helpers';
import InternalNotes from '../ViewOrder/internalNotes';
import { Button } from 'react-bootstrap';
import imageCompression from 'browser-image-compression';


const ViewOrderProductPage = () => {
  const orderData = useSelector((state) => state.order.orderData);
  const { id, variantId } = useParams();
  const [searchParams] = useSearchParams();
  const itemId = searchParams.get('itemId');
  const dispatch = useDispatch();
  const [thisOrderItem, setThisOrderItem] = useState();
  const [instruction, setInstruction] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [orderImages, setOrderImages] = useState();
  const [uploadImageForm, setUploadImageForm] = useState(false);
  const [fullBodyPic, setFullBodyPic] = useState();
  const [photoId, setPhotoId] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [userVideo, setUserVideo] = useState();
  const [videoSource, setVideoSource] = useState();
  const [videoFile, setVideoFile] = useState();

  useEffect(() => {
    if (orderData) {
      let thisProductData = orderData?.orderItem.find(
        (el) => el._id === itemId,
      );
      setThisOrderItem(thisProductData);
      setInstruction(thisProductData?.instruction || '');
      api.getOrderImages(orderData._id).then((res) => {
        setOrderImages(res.data.data[0]);
        setUserVideo(res.data.data[0].userVideo)
      });
    }
  }, [orderData]);

  useEffect(() => {
    videoRef.current?.load();
  }, [userVideo]);

  const videoRef = React.useRef();

  const onChangeOfInstructionInput = (e) => {
    setInstruction(e.target.value);
  };

  const handleUploadBodyImage = async (imageData) => {
    const imageFile = imageData.answer;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      api
      .uploadOrderImage(getFormDataFromObject({ photos: compressedFile }))
      .then((res) => {
        setFullBodyPic(res.data.data.photos[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    } catch (error) {
      console.log(error);
    }
   
  };

  const handleUploadIDImage = async (imageData) => {
    const imageFile = imageData.answer;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      api
      .uploadOrderImage(getFormDataFromObject({ photos: compressedFile }))
      .then((res) => {
        setPhotoId(res.data.data.photos[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const submitOrderImages = () => {
    let payload = {};

    if (fullBodyPic != null && photoId != null) {
      payload = {
        userPicture: fullBodyPic,
        userIdPicture: photoId,
      };
    } else if (fullBodyPic != null && photoId == null) {
      payload = {
        userPicture: fullBodyPic,
      };
    } else if (fullBodyPic == null && photoId != null) {
      payload = {
        userIdPicture: photoId,
      };
    }
    if (videoFile != null) {
      payload = {...payload, userVideo: videoFile}
    }
    setButtonClicked(true);
    api
      .submitOrderImage(orderData._id, payload)
      .then((res) => {
        if (res.data.message == 'Image uploded successfully') {
          navigate(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveInstructions = () => {
    const payload = {
      instruction: instruction,
    };

    api
      .setInstructionForProductLabel(thisOrderItem?._id, payload)
      .then((res) => {
        toastSuccess('Added Instruction Successfully');
        dispatch(getOrderById(id));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleFileChange = async (event) => {
    setButtonClicked(true);
    const file = event.target.files[0];
    if (!file) return;
    setVideoSource(URL.createObjectURL(file));
    api
      .uploadOrderVideo(getFormDataFromObject({ video: file }))
      .then((res) => {
        setVideoFile(res.data.data);
        setButtonClicked(false);
      })
      .catch((err) => {
        toastError('Something went wrong!');
        setButtonClicked(false);
        console.log(err);
      });
  };

  return (
    <>
      {!uploadImageForm ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <ol className="breadcrumb">
                <li>
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(emptyOrderData());
                      navigate(
                        location.state?.allOrdersPath
                          ? location.state?.allOrdersPath
                          : -2,
                        {
                          state: {
                            ...location.state,
                          },
                        },
                      );
                    }}
                    title="Product Management"
                  >
                    All Orders
                  </p>
                </li>
                <li>
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/vieworder/${id}`);
                    }}
                    title="Product Management"
                  >
                    View Order
                  </p>
                </li>
                <li>View Order Product</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xl-12 col-xxl-6 mb-3">
              <ViewOrderDetails key={orderData?._id} orderData={orderData} />
            </div>
            <div className="col-md-12 col-xl-12 col-xxl-6 mb-3">
              <CustomerDetails key={orderData?._id} orderData={orderData} />
            </div>
          </div>
          {(orderImages?.userPicture || orderImages?.userIdPicture) && (
            <div className="box_main">
              <div className="header_top">
                <h3>Full Body Picture & Photo Id</h3>
              </div>
              <div className="row order-images-section">
                {orderImages?.userPicture?.isdeleted ? (
                  <p>User Image was uploaded</p>
                ) : (
                  <>
                    {orderImages?.userPicture?.location ? (
                      <img
                        src={orderImages?.userPicture?.location}
                        alt=""
                        style={{
                          borderRadius: '16px',
                          width: '300px',
                          height: '150px',
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {orderImages?.userIdPicture?.isdeleted ? (
                  <p>User ID was uploaded</p>
                ) : (
                  <>
                    {' '}
                    {orderImages?.userIdPicture?.location ? (
                      <img
                        src={orderImages?.userIdPicture?.location}
                        alt=""
                        style={{
                          borderRadius: '16px',
                          width: '300px',
                          height: '150px',
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {userVideo?.location ? (
            <>
              <video width="350" height="200" controls >
                <source src={userVideo.location} type="video/mp4"/>
              </video>
            </>
          ) : (
            <></>
          )}
          <button
            className={`btn btn-primary border-radius-24 fs-16 shadow-none shadow-focus-none my-1`}
            onClick={() => setUploadImageForm(true)}
          >
            Upload ID or full body image
          </button>
          {thisOrderItem?.product?.type === 'medication' ? (
            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className="header_top "
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Add Instructions</h3>
                </div>

                <div className="d-flex">
                  <div
                    style={{
                      width: '40%',
                    }}
                  >
                    <textarea
                      disabled={isUserPharmacist()}
                      className="mt-4 form-control form-textarea"
                      rows="3"
                      value={instruction}
                      onChange={(e) => onChangeOfInstructionInput(e)}
                      placeholder="Write the instructions here..."
                      style={{ width: '100%', borderRadius: '0.5rem' }}
                    />
                    {instruction != '' &&
                    (!thisOrderItem.instruction ||
                      thisOrderItem.instruction != instruction) ? (
                      <span className="text-danger">
                        {thisOrderItem.instruction &&
                        thisOrderItem.instruction != instruction
                          ? `You have updated the instruction but haven't saved it.`
                          : `Instruction is not saved yet.`}
                      </span>
                    ) : null}
                  </div>
                  <div className="mt-4 ms-3">
                    {isUserDoctor() || isUserAdmin() ? (
                      <button
                        className="btn btn-primary mt-3"
                        onClick={saveInstructions}
                        disabled={!instruction || instruction == ''}
                      >
                        Save
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="box_main">
                  <div className="middle_main">
                    <InternalNotes />
                  </div>
                </div>
              </div>
              {(isUserDoctor() || isUserAdmin()) && (
                <div className="col-md-12">
                  <ProductQuestions
                    OrderItem={thisOrderItem}
                    orderStatus={orderData.current_order_status}
                  />
                </div>
              )}
            </div>
          ) : null}
        </>
      ) : (
        <>
          <div
            className="
           "
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="box_main">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => setUploadImageForm(false)}
                    >
                      Back
                    </Button>
                    <div className="d-flex">
                      {/* <label
                        className="p-2 text-center mt-2 mx-2 d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: '32px',
                          height: '52px',
                          border: '1px solid #ccc',
                          display: 'inline-block',
                          padding: ' 10px 22px',
                          cursor: 'pointer',
                          fontSize: '15px',
                        }}
                      >
                        {fullBodyPic ? (
                          <span className={``} onClick={() => {}}>
                            {fullBodyPic.originalname}
                          </span>
                        ) : (
                          'Upload your Full body Picture'
                        )}
                        <input
                          type="file"
                          accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp, image/heic"
                          style={{ width: '300px', display: 'none' }}
                          onChange={(e) => {
                            if (validateFileExtensions(e.target.files[0])) {
                              handleUploadBodyImage({
                                answer: e.target.files[0],
                                prevUrl: URL.createObjectURL(e.target.files[0]),
                              });
                            }
                          }}
                        />
                      </label> */}

                      <label
                        className="p-2 text-center mt-2 mx-2 d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: '32px',
                          height: '52px',
                          border: '1px solid #ccc',
                          display: 'inline-block',
                          padding: ' 10px 22px',
                          cursor: 'pointer',
                          fontSize: '15px',
                        }}
                      >
                        {photoId ? (
                          <span className={``} onClick={() => {}}>
                            {photoId.originalname}
                          </span>
                        ) : (
                          'Upload a photo ID'
                        )}
                        <input
                          type="file"
                          accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp, image/heic"
                          style={{ width: '300px', display: 'none' }}
                          onChange={(e) => {
                            if (validateFileExtensions(e.target.files[0])) {
                              handleUploadIDImage({
                                answer: e.target.files[0],
                                prevUrl: URL.createObjectURL(e.target.files[0]),
                              });
                            }
                          }}
                        />
                      </label>

                      <label
                        className="p-2 text-center mt-2 mx-2 d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: '32px',
                          height: '52px',
                          border: '1px solid #ccc',
                          display: 'inline-block',
                          padding: ' 10px 22px',
                          cursor: 'pointer',
                          fontSize: '15px',
                        }}
                      >
                        Upload user video
                        <input
                          type='file'
                          className='form-control form-control-sm'
                          id='media-URL'
                          accept='.MOV, .mp4, video/*'
                          onChange={(event) => {
                            handleFileChange(event);
                          }}
                          placeholder='Upload'
                          display='none'
                        />
                      </label>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          submitOrderImages();
                        }}
                        className={`btn btn-primary border-radius-24 fs-16 shadow-none shadow-focus-none my-1`}
                        disabled={buttonClicked}
                      >
                        {buttonClicked ? 'Loading...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewOrderProductPage;
