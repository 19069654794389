import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactTextareaAutosize from 'react-textarea-autosize';
import services from '../../../api/api';
import { toastSuccess } from '../../../common/UI/Toasts';

const Communications = (props) => {
  const { orderData } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const [chatData, setChatData] = useState([]);
  const [messageContent, setMessageContent] = useState();

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (orderData?._id) getMessagesForThisOrder();
  }, [orderData]);

  const temp = [
    {
      message: 'What is your name?',
      user: 'Romil',
      date: '12/03/2022-12:35 AM',
      id: 'a',
    },
    {
      message: 'My name is XXX',
      user: 'Customer',
      date: '12/03/2022-01:35 AM',
      id: 'b',
    },
  ];

  const getMessagesForThisOrder = () => {
    services
      .getMessages(orderData?._id)
      .then((res) => {
        setChatData(res?.data?.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const postMessageForThisOrder = () => {
    const payload = {
      order_id: orderData?._id,
      message: messageContent,
      customer_id: orderData?.user?._id,
      customer_name: `${orderData?.user?.first_name} ${orderData?.user?.last_name}`,
      customer_email: `${orderData?.user?.email}`,
    };
    services
      .postMessage(payload)
      .then((res) => {
        toastSuccess('Message Sent Successfully');
        getMessagesForThisOrder();
        setMessageContent('');
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const videoRef = React.useRef();

  return (
    <div>
      <div className="header_top " style={{ justifyContent: 'space-between' }}>
        <h3>Communications</h3>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            toggleExpanded();
          }}
        >
          {isExpanded ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </div>
      </div>
      {isExpanded ? (
        <>
          {chatData.map((el) => {
            let dateAndTime = `${el?.createdAt.substring(
              0,
              10,
            )} ${el?.createdAt.substring(11, 16)}`;

            return (
              <div
                key={el._id}
                className="mb-2"
                style={{ padding: '6px', backgroundColor: '#ddddddbf' }}
              >
                <div
                  className="d-flex fs-16"
                  style={{
                    justifyContent: 'space-between',
                    fontSize: '14px',
                  }}
                >
                  <div>{el.sender_name}</div>
                  <div>{dateAndTime}</div>
                </div>
                {el?.isVideo && !el?.isdeleted && 
                  <video ref={videoRef} width='260' height='200' controls>
                    <source src={el?.mediaFile?.location} />
                    Your browser does not support the video tag.
                  </video>
                }
                {el?.isImage && !el?.isdeleted && 
                  <img
                    src={el?.mediaFile?.location}
                    style={{
                      width: '200px',
                      height: 'auto'
                    }}
                  />
                }
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '4px',
                    borderRadius: '4px',
                    fontSize: '16px',
                  }}
                >
                  {el.message}
                </div>
              </div>
            );
          })}
          <div
            className="d-flex mt-3 align-items-center mx-3"
            style={{ justifyContent: 'space-between' }}
          >
            <ReactTextareaAutosize
              placeholder="Add Question"
              className="mb-0 col-md-8 form-group form-input p-2"
              rows={1}
              style={{ resize: 'none' }}
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
            />
            <Button
              style={{ height: 'fit-content' }}
              disabled={messageContent && messageContent != '' ? false : true}
              onClick={postMessageForThisOrder}
              className="order_btns"
            >
              Send
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Communications;
