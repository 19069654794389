import React from 'react'
import { Form, Formik } from 'formik';
import Input from '../../common/FormComponents/Input';
import api from '../../api/api';
import {
  getErrorMessage,
  login,
  isLoggedIn,
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
} from '../../utils/helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toastError, toastSuccess } from '../../common/UI/Toasts';

const ValidateOTPStep = () => {
  const navigate = useNavigate();

  let initialValues = {
    otp: ''
  }

  const onSubmit = (values) => {
    api.verifyOTP({otp: values.otp})
    .then((res) => {
      let user = res.data.data
      let fullname = `${user?.first_name} ${user?.last_name}`;
      login(
        user?.token,
        user?._id,
        fullname,
        user?.first_name,
        user?.photo?.location,
        user?.role,
      );
      toastSuccess('Logged In Successfully!!');
      if (isUserAdmin() || isUserDoctor()) {
        navigate('/orders-pending', { replace: true });
      } else if (isUserPharmacist()) {
        navigate('/orders-assigned', { replace: true });
      }
    })
    .catch((err) => {
      toastError(err.message)
    });
  }

  return (
    <section className="login_main mt-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 mx-auto">
            <div className="middle_main">
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-xl-12 form-group">
                          <p>Verify OTP</p>
                        </div>
                        <Input
                          name="otp"
                          id="otp"
                          type="text"
                          label="Enter OTP"
                          labelclassname="form-label"
                          placeholder=""
                          className="form-control form-input mt-3"
                          isRequired
                        />
                        <button
                          type="submit"
                          className="btn btn-success my-2 me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValidateOTPStep
