import * as network from './network';

const login = (payload) => {
  return network.post(`user/login`, payload, false);
};

const getUserData = () => {
  return network.get(`user/profile`, {});
};

const getUsers = (payload) => {
  return network.get(`user/list`, payload, true);
};

const getDuplicateUsers = (payload) => {
  return network.get(`duplicate-users/all`, payload, true);
};

const updateUserStatus = (id, payload) => {
  return network.put(`user/${id}`, payload, true);
};

const getUsersOnSearch = (payload) => {
  return network.get(`user/search`, payload, true);
};

const getUserById = (id) => {
  return network.get(`user/${id}`, {}, true);
};

const register = (payload) => {
  return network.post(`user/register`, payload, false, true);
};

const updateUserById = (id, payload) => {
  return network.put(`user/update-record/${id}`, payload, true, true);
};

const updatePasswordById = (payload) => {
  return network.put(`user/changePassword`, payload, true);
};

const validateUser = (id) => {
  return network.put(`user/verify/` + id, {}, true);
};

const approveDuplicateUser = (id) => {
  return network.put(`duplicate-users/` + id, {}, true);
};

const getUserPastOrders = (payload) => {
  return network.get(`order`, payload, true);
};

const getUserLexisNexisReport = (id) => {
  return network.get(`user/downloadLexisNexisReport/` + id, {}, true);
};

const getAddressesByUserId = (id) => {
  return network.get(`address/user/${id}`, {}, true);
};

const updateUserAddress = (id, payload) => {
  return network.put(`address/${id}`, payload, true);
};

const checkIfUserExists = (payload) => {
  return network.post(`user/checkEmailExists`, payload);
};

const deleteAddress = (id) => {
  return network.delet(`address/${id}`, true);
};

const getQRCode = (payload) => {
  return network.post(`user/generateQR`, payload, true, false, true);
};

const verifyOTP = (payload) => {
  return network.post(`user/verifyotp`, payload, true, false, true);
};

const resetAuthenticator = (payload) => {
  return network.post(`user/resetAuth`, payload);
};


export default {
  login,
  getUserData,
  getUsers,
  updateUserStatus,
  getUsersOnSearch,
  getUserById,
  register,
  updateUserById,
  updatePasswordById,
  validateUser,
  getDuplicateUsers,
  approveDuplicateUser,
  getUserPastOrders,
  getUserLexisNexisReport,
  getAddressesByUserId,
  updateUserAddress,
  checkIfUserExists,
  deleteAddress,
  getQRCode,
  verifyOTP,
  resetAuthenticator
};
