import React from "react";
import SetupGoogleAuth from "../components/SetupGoogleAuth";

const SetupQR = () => {
  return (
    <div>
      <SetupGoogleAuth  />
    </div>
  )
}

export default SetupQR;
