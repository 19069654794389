import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import OrderTable from '../components/Orders/orderTable';
import services from '../api/api';
import { isUserAdmin, isUserDoctor, isUserPharmacist } from '../utils/helpers';
import fridgeIcon from '../assets/images/fridge.svg';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { orderSearchbarPlaceholder } from '../common/constants';

const CSVheaders = [
  { label: 'Order Number', key: 'order_number' },
  { label: 'Customer Name', key: 'customer_name' },
  { label: 'Total Price', key: 'total_price' },
  { label: 'Total Items', key: 'total_items' },
  { label: 'Email Address', key: 'email' },
  { label: 'Delivery Service', key: 'delivery_service' },
  { label: 'Date', key: 'date' },
  { label: 'Assigned Doctor', key: 'doctor_name' },
  { label: 'Assigned Pharmacist', key: 'pharmacist_name' },
  { label: 'Status', key: 'status' },
];

const MlpOrdersManagement = () => {
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    setLoading(true);
    getOrders(currentPageNo);
  }, [currentPageNo, seeAll, searchVal]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getOrders = (pageNo, value = null) => {
    let params = seeAll
      ? { showall: true, status: 'placed', pharmacist_id: '642492ea5f255f8e3ca0d525' }
      : {
          perPage: 10,
          page: pageNo,
          status: 'placed',
          pharmacist_id: '642492ea5f255f8e3ca0d525'
        };
    if (isUserAdmin()) params = { ...params, sortFor: 'admin' };

    // orders which are associated to that doc and yet is not reviewed by him
    if (isUserDoctor())
      params = {
        ...params,
        sortFor: 'doctor',
        doctor_id: userId,
        has_medicine: true,
        pendingDoctorOrder: true,
      };

    if (searchVal)
      params = {
        ...params,
        search: searchVal,
      };

    services
      .getAllOrders(params)
      .then((res) => {
        let data = res?.data?.data?.docs;
        setData(data);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
        loadCSVdata(res.data?.data?.docs);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
      status: 'placed',
    };
    services
      .getOrdersOnSearch(params)
      .then((res) => {
        let data = res?.data?.data?.docs;
        setData(data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleOrderSearch = (value) => {
    setSearchVal(value);
  };

  const onSearchDebounce = debounce(handleOrderSearch, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const loadCSVdata = (orderdata) => {
    const modifyorderdata = orderdata?.length
      ? orderdata?.map((el) => {
          return {
            order_number: el?.order_number,
            customer_name: `${el?.user?.first_name} ${el?.user?.last_name}`,
            email: el?.user?.email,
            total_price: el?.total,
            total_items: el?.total_items,
            status: el?.current_order_status,
            delivery_service: el?.delivery?.title,
            doctor_name: `${el?.doctor?.first_name || ''} ${
              el?.doctor?.last_name || ''
            }`,
            pharmacist_name: `${el?.pharmacist?.first_name || ''} ${
              el?.pharmacist?.last_name || ''
            }`,
            date: `${moment(el?.createdAt).format('DD/MM/yyyy hh:mm A')}`,
          };
        })
      : [];

    setCsvData(modifyorderdata);
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top d-flex"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Order Management</h3>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {(isUserAdmin() || isUserDoctor() || isUserPharmacist) && (
                      <span style={{ fontSize: '14px', color: 'blue' }}>
                        First order from user
                      </span>
                    )}
                    {(isUserAdmin() || isUserDoctor()) && (
                      <span style={{ fontSize: '14px', color: 'green' }}>
                        New message received
                      </span>
                    )}
                    {isUserAdmin() && (
                      <span style={{ fontSize: '14px', color: 'red' }}>
                        Rejected by doctor
                      </span>
                    )}
                  </div>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={onSearchDebounce}
                    searchPlaceholder={orderSearchbarPlaceholder}
                    buttonName={
                      isUserAdmin() ? (
                        <CSVLink
                          data={csvData}
                          filename={`orders_${moment().format(
                            'YYYY_MM_DD_HH_mm_ss',
                          )}.xlsx`}
                          target="_blank"
                          headers={CSVheaders}
                          style={{ color: '#fff' }}
                        >
                          Export Orders
                        </CSVLink>
                      ) : null
                    }
                    loading={loading}
                    seeAll={seeAll}
                    seeAllHandle={seeAllHandle}
                  />
                  <OrderTable
                    loading={loading}
                    prevHandle={prevHandle}
                    nextHandle={nextHandle}
                    data={data}
                    seeAll={seeAll}
                    totalPage={totalPage}
                    currentPageNo={currentPageNo}
                    tableFor={'Pending'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MlpOrdersManagement;
