import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { HeadersWithAuth, HeadersWithoutAuth, logout } from '../utils/helpers';
// eslint-disable-next-line
const API_HOST = `${process.env.REACT_APP_API_HOST}`;
import React from 'react';

axios.interceptors.request.use(
  function (config) {
    // config.headers.Authorization = localStorage.getItem('token');
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // return Promise.reject(error);
    throw error;
  },
);

export const get = (
  url,
  params,
  isAuth = true,
  responseType = 'json',
  config = {},
) => {
  const setHeader = isAuth ? HeadersWithAuth() : HeadersWithoutAuth();
  return axios
    .get(API_HOST + url, {
      headers: setHeader,
      params: params,
      responseType: responseType,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => handleError(error));
};

export const post = (
  url,
  payload,
  isAuth = true,
  isFileUpload = false,
  loginToken = false,
  config = {},
) => {
  const setHeader = isAuth
    ? HeadersWithAuth(isFileUpload, loginToken)
    : HeadersWithoutAuth(isFileUpload);
  return axios
    .post(API_HOST + url, payload, {
      headers: setHeader,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleError(error);
    });
};

// Naming 'delet' is not a mistake
// 'delete' is reserved keyword we can not use.
export const delet = (url, isAuth = true, params) => {
  const setHeader = isAuth ? HeadersWithAuth() : HeadersWithoutAuth();
  return axios
    .delete(API_HOST + url, {
      headers: setHeader,
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => handleError(error));
};

export const put = (url, payload, isAuth = true, isFileUpload = false) => {
  const setHeader = isAuth
    ? HeadersWithAuth(isFileUpload)
    : HeadersWithoutAuth(isFileUpload);
  return axios
    .put(API_HOST + url, payload, {
      headers: setHeader,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(`error`, error);
      handleError(error);
    });
};

export const handleError = (error) => {
  if (error?.response?.status === 403 || error?.response?.status === 401) {
    logout();
    window.location.href = '/login';
  }
  console.log('User not supported or API error');
  throw error.response.data;
};
