import React, {useState} from 'react'
import ImageInput from '../../../common/FormComponents/ImageInput';
import {
  DIMENSION,
  VARIANT_ATTRIBUTE_OPTIONS,
} from '../../../common/constants';
import {Button, Spinner } from 'react-bootstrap';
import { toastError } from '../../../common/UI/Toasts';
import ReactTextareaAutosize from 'react-textarea-autosize';
import TextEditor from '../../../common/TextEditor';
import editIcon from '../../../assets/images/pencil.svg';
import deleteIcon from '../../../assets/images/trash.svg';
import TextError from '../../../common/FormComponents/TextError';
import Input from '../../../common/FormComponents/Input';
import { Formik, Form, FieldArray, Field, FormikProvider } from 'formik';

const PreAssessmentForm = ({productData, handleChange, handlePrevious, isSubmitDisabled, handleSubmit, deleteFAQAndTickPoints, productId}) => {
  const [currentFAQObj, setCurrentFAQObj] = useState({
    question: '',
    answer: '',
  });

  const setFAQEditValue = (index) => {
    setCurrentFAQObj({ ...productData?.preRegFaq[index], index: index });
  };

  return (
    <>
        <Formik
          initialValues={productData}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validateOnChange={false}
        >
         {(formik) => {
          const { touched, errors, values, setFieldValue, setFieldError } =
            formik;
            return (
              <Form>
                <div className="row">
                  <div className="col-12 mt-2 mb-4">
                  </div>
                  <div className="col-12">
                    <h4 className="middle_title">Parallel Product Page</h4>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-1 mb24">
                      <hr />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Title"
                        labelClassname="form-label"
                        name="preRegName"
                        type="text"
                        className="form-control form-input"
                        placeholder="Title"
                        isRequired
                      />
                    </div>
                    <div className="col-12">
                      <h4 className="middle_title">
                        Product Image
                      </h4>
                    </div>
                  </div>
                  <ImageInput
                    label={`Upload Image (w x h: ${DIMENSION.product.width} x ${DIMENSION.product.height})`}
                    accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp"
                    name={'preRegPhotos'}
                    id={'preRegPhotos'}
                    imagesLimit={5}
                    productId={productId}
                    imageDimention={DIMENSION.product}
                    isRequired
                  />

                  <div className="col-lg-12 col-xl-8 form-group">
                    <label className="form-label">Description</label>
                    <TextEditor
                      name="preRegDescription"
                      value={formik.values.preRegDescription}
                      className={'position-relative'}
                      onEditorChange={(value) => {
                        formik.setFieldValue('preRegDescription', value, true);
                      }}
                      editorHeight="25vh"
                    />
                    {touched['preRegDescription'] && errors['preRegDescription'] && (
                      <TextError>{errors['preRegDescription']}</TextError>
                    )}
                  </div>

                  <div className="col-12">
                    <div className="d-flex align-items-center">
                      <label className="form-label" htmlFor={'faq'}>
                        Add FAQs:
                      </label>
                      <Button
                        className="ms-4"
                        onClick={() => {
                          if (
                            currentFAQObj?.question !== '' &&
                            currentFAQObj?.answer !== ''
                          ) {
                            handleChange('preRegFaq', currentFAQObj);
                            setCurrentFAQObj({
                              question: '',
                              answer: '',
                            });
                          } else {
                            toastError('Question and Answer both are required.');
                          }
                        }}
                      >
                        Save Changes
                      </Button>
                    </div>
                      
                    <div className="ms-4">
                      <div className="d-flex mt-3">
                        <label
                          className="form-label mb-0"
                          style={{
                            fontSize: '0.8rem',
                          }}
                        >
                          Question
                        </label>
                        <ReactTextareaAutosize
                          type="text"
                          className="form-control ms-3 w-50"
                          placeholder=""
                          onChange={(e) =>
                            setCurrentFAQObj((prev) => {
                              return {
                                ...prev,
                                question: e.target.value,
                              };
                            })
                          }
                          value={currentFAQObj?.question}
                          minRows={1}
                        />
                      </div>
                      <div className="d-flex mt-3">
                        <label
                          className="form-label mb-0"
                          style={{
                            fontSize: '0.8rem',
                          }}
                        >
                          Answer
                        </label>
                        <TextEditor
                          className={'w-50 ms-4'}
                          onEditorChange={(value) => {
                            setCurrentFAQObj((prev) => {
                              return {
                                ...prev,
                                answer: value,
                              };
                            });
                          }}
                          value={currentFAQObj?.answer}
                          editorHeight="30vh"
                        />
                      </div>
                    </div>
                    {productData?.preRegFaq?.length
                      ? productData?.preRegFaq?.map((el, index) => {
                          return (
                            <>
                              {el?.question != '' ? (
                                <React.Fragment key={index}>
                                  <div className="mb-3 w-100">
                                    <div className="d-flex justify-content-between">
                                      <label
                                        className="form-label mb-0 fs-18 w-100"
                                        style={{
                                          wordWrap: 'break-word',
                                        }}
                                      >
                                        Q) {el?.question}
                                      </label>
                                      <div className="d-flex">
                                        <span onClick={() => setFAQEditValue(index)}>
                                          <img
                                            src={editIcon}
                                            alt="pencil"
                                            width="20"
                                            height="20"
                                          />
                                        </span>
                                        <span
                                          onClick={() => {
                                            if (
                                              (currentFAQObj?.index ||
                                                currentFAQObj?.index === 0) &&
                                              currentFAQObj?.index === index
                                            )
                                              setCurrentFAQObj({
                                                question: '',
                                                answer: '',
                                              });
                                            deleteFAQAndTickPoints('preRegFaq', index);
                                          }}
                                        >
                                          <img
                                            src={deleteIcon}
                                            alt="trash"
                                            width="20"
                                            height="20"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: '#d3d3d359',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        wordWrap: 'break-word',
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: el?.answer,
                                      }}
                                      className="w-100"
                                    ></div>
                                  </div>
                                </React.Fragment>
                              ) : null}
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="footer_main" style={{ justifyContent: 'space-between' }}>
                  <div>
                    <button
                      onClick={() => handlePrevious()}
                      type="button"
                      className="btn btn-sky my-2 me-2"
                    >
                      Previous
                    </button>
                  </div>
                  <button
                    disabled={isSubmitDisabled}
                    type="submit"
                    className={`btn ${
                      productData.is_subscribable || productData.has_landingpage || productData.has_pre_assessment
                        ? 'btn-primary'
                        : 'btn-success'
                    } my-2 me-2`}
                  >
                    {isSubmitDisabled && (
                      <span className="submit-loader">
                        <Spinner animation="border" size="sm" />
                      </span>
                    )}{' '}
                    {productData.is_subscribable || productData.has_landingpage
                    ? 'Next'
                    : 'Submit'}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
        
    </>
  )
}

export default PreAssessmentForm;
