import React from "react";
import ValidateOTPStep from "../components/ValidateOTPStep";

const ValidateOTP = () => {
  return (
    <div>
      <ValidateOTPStep />
    </div>
  )
}

export default ValidateOTP;
